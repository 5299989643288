// Admin custom styles

.bg-traffic1 {
  background: #4a4a4a;
}

.bg-traffic2 {
  background: #f5a623;
}

.bg-chart {
  background-color: #172b4d !important;
}

a.text-muted:hover {
  color: #007bff !important;
}

.title-icon {
  padding: 7px;
  margin-right: 10px;

  i {
    font-size: 0.9rem;
  }
}

.navbar-search {
  .input-group {
    background: transparent;
    border: 1px solid white;
    box-shadow: unset;
    color: white;

    i {
      color: white;
    }

    input {
      color: white;
      width: auto;

      &::placeholder {
        color: white;
        opacity: 0.8;
      }
    }
  }
}

.select-wrap {
  label,
  select {
    display: inline-block;
    color: white;
  }

  label {
    font-size: 0.9rem;
    font-weight: normal;
  }

  select {
    font-size: 1rem;
    font-weight: bold;
    border: none;
    box-shadow: unset;

    &.form-control {
      width: auto;
    }

    &:focus,
    &:active {
      color: white;
    }

    option {
      background: #333;
      text-shadow: none;
    }
  }
}

.owner-header {
  h6 {
    font-size: 11px;
    font-weight: bold;
    color: #888;
    margin-bottom: 15px;
  }

  .entity-name {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 875px) {
      flex-basis: 100% !important;
      flex-grow: 1;
    }
  }

  p,
  span {
    color: #222;
    font-size: 13px;
    font-weight: normal;
    line-height: 16px;
  }

  img {
    width: 160px;
    height: auto;
  }

  .col {
    flex-basis: auto;
    width: auto;
  }
}

.pdf-wrapper {
  embed {
    width: 100%;
    height: 80vh;
    border-radius: 12px;
  }
}

.dashboard-header {
  padding-top: 80px;
  margin-top: -80px;
  background: #3e3f40;
}

.header,
.navbar-top,
.navbar-search,
.container-fluid,
.owner-header-wrap {
  position: relative;
  z-index: 10;
}

.header-body {
  margin-bottom: 30px !important;
}

.owner-header-wrap {
  position: relative;
  z-index: 10;
}

.daterangepicker {
  cursor: pointer;
}

.calendar-wrap {
  .rdrDefinedRangesWrapper,
  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrMonthName {
    text-align: center;
    font-size: 1rem;
    color: $title-color;
  }

  .rdrDateRangeWrapper,
  .rdrMonths {
    width: 100%;
    justify-content: space-between;
  }

  .rdrMonth {
    width: 95%;
  }

  .rdrDateRangePickerWrapper {
    width: 100%;
    margin: 5% 2% auto;
    display: block;
  }

  .rdrDay {
    border: 1px solid $cal-border-color;
    height: 4.5vw;
    line-height: unset;
    margin: 0 auto;
  }

  .rdrMonthAndYearPickers,
  .rdrDocTypes {
    justify-content: center;
  }

  .rdrDayPassive span,
  .rdrDayToday .rdrDayNumber span:after {
    display: none;
  }

  .rdrDayEndOfWeek .rdrInRange,
  .rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    right: 0;
  }

  .rdrDayStartOfWeek .rdrEndEdge,
  .rdrDayStartOfWeek .rdrInRange {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    left: 0;
  }

  .rdrDayStartPreview,
  .rdrStartEdge {
    left: 5em;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .rdrDayEndPreview,
  .rdrEndEdge {
    right: 5em;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview,
  .rdrDayNumber {
    top: 0;
    bottom: 0;
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
    color: $title-color;
  }

  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
    color: $title-color;
  }

  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: white;
  }

  .rdrDayStartPreview,
  .rdrDayEndPreview,
  .rdrDayInPreview {
    display: none;
  }

  .rdrStartEdge {
    height: 1rem;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 0;

    &::before {
      content: "In";
      position: absolute;
      left: 1em;
      top: -1px;
      color: $title-color;
      font-size: 0.8rem;
    }
  }

  .rdrInRange {
    height: 1rem;
  }

  .rdrEndEdge {
    height: 1rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 10px;

    &::after {
      content: "Out";
      position: absolute;
      right: 1em;
      top: -1px;
      color: $title-color;
      font-size: 0.75rem;
    }
  }

  .rdrStartEdge.rdrEndEdge,
  .rdrDayStartPreview,
  .rdrDayEndPreview {
    right: 0px !important;
    left: 0px !important;
    height: 1rem !important;
    border-top-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;

    &::before {
      content: "In";
      position: absolute;
      left: 10px !important;
      top: -1px;
      color: $title-color;
      font-size: 0.8rem;
    }

    &::after {
      content: "Out";
      position: absolute;
      left: 60px !important;
      top: -1px;
      color: $title-color;
      font-size: 0.75rem;
    }
  }

  h3 {
    font-size: 0.9rem;
    font-weight: bold;
  }

  .cancel-btn {
    display: block;
    text-align: center;
    font-size: 0.875rem;
    font-weight: bold;
    color: $link-color;
    background: none;
    box-shadow: unset;

    &:hover {
      opacity: 0.8;
      transform: unset;
    }

    &:active,
    &:focus {
      outline: unset;
    }
  }

  .form-control[readonly] {
    background-color: $white-color;
  }

  .rdrDayNumber {
    width: 100%;
    height: 100%;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }

  .type-info {
    background: $white-color;
    padding: 10px 15px;
    border-radius: 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    &.place-top {
      margin-top: -5px;

      &::after {
        border-top-color: $white-color;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        bottom: -9px;
        border-top-width: 9px;
      }
    }

    &.show {
      opacity: 1 !important;
    }
  }

  .badge {
    padding: 0.2rem 0.375rem;

    i {
      width: 0.45rem;
      height: 0.45rem;
      margin-right: 0.1rem;
    }
  }
}

.tooltip-wrap {
  width: 220px;
  text-align: left;

  label {
    color: $input-color;
    font-size: 0.7rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  h3 {
    color: $title-color;
    font-size: 1rem;
  }

  p {
    color: $label-color;
    font-size: 0.8rem;
    font-weight: 500;

    &.confirm {
      font-weight: bold;
      color: $step-bgcolor;
    }

    &.in-progress {
      font-weight: bold;
      color: #f5a623;
    }
  }
}

// @media (min-width: 1121px) {
//     .calendar-wrap {

//         .rdrDayStartPreview,
//         .rdrStartEdge {
//             left: 8em;
//             border-top-left-radius: 8px;
//             border-bottom-left-radius: 0px;
//         }

//         .rdrDayEndPreview,
//         .rdrEndEdge {
//             right: 8em;
//             border-top-right-radius: 0px;
//             border-bottom-right-radius: 8px;
//         }

//         .rdrStartEdge.rdrEndEdge,
//         .rdrDayStartPreview,
//         .rdrDayEndPreview {
//             right: 0px !important;
//             left: 0px !important;
//             height: 1rem !important;
//             border-top-left-radius: 10px !important;
//             border-bottom-right-radius: 10px !important;

//             &::before {
//                 content: 'In';
//                 position: absolute;
//                 left: 1em !important;
//                 top: -1px;
//                 color: $title-color;
//                 font-size: 13px;
//             }

//             &::after {
//                 content: 'Out';
//                 position: absolute;
//                 left: 4em !important;
//                 top: -1px;
//                 color: $title-color;
//                 font-size: 12px;
//             }
//         }
//     }
// }

// @media (max-width: 1300px) {
//     .calendar-wrap {

//         .rdrDayStartPreview,
//         .rdrStartEdge {
//             left: 4em;
//             border-top-left-radius: 8px;
//             border-bottom-left-radius: 0px;
//         }

//         .rdrDayEndPreview,
//         .rdrEndEdge {
//             right: 4em;
//             border-top-right-radius: 0px;
//             border-bottom-right-radius: 8px;
//         }

//         .rdrStartEdge.rdrEndEdge,
//         .rdrDayStartPreview,
//         .rdrDayEndPreview {
//             right: 0px !important;
//             left: 0px !important;
//             height: 1rem !important;
//             border-top-left-radius: 10px !important;
//             border-bottom-right-radius: 10px !important;

//             &::before {
//                 content: 'In';
//                 position: absolute;
//                 left: 1em !important;
//                 top: -1px;
//                 color: $title-color;
//                 font-size: 13px;
//             }

//             &::after {
//                 content: 'Out';
//                 position: absolute;
//                 left: 4em !important;
//                 top: -1px;
//                 color: $title-color;
//                 font-size: 12px;
//             }
//         }
//     }
// }

// @media (min-width: 1447px) {
//     .calendar-wrap {

//         .rdrDayStartPreview,
//         .rdrStartEdge {
//             left: 5em;
//             border-top-left-radius: 8px;
//             border-bottom-left-radius: 0px;
//         }

//         .rdrDayEndPreview,
//         .rdrEndEdge {
//             right: 5em;
//             border-top-right-radius: 0px;
//             border-bottom-right-radius: 8px;
//         }
//     }
// }

@media (min-width: 1023px) {
  .calendar-wrap {
    .rdrDayStartPreview,
    .rdrStartEdge {
      left: 3.6em;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 0px;
    }

    .rdrDayEndPreview,
    .rdrEndEdge {
      right: 3.6em;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 8px;
    }

    .rdrStartEdge {
      &::before {
        content: "In";
        position: absolute;
        left: 0.6em !important;
        top: -1px;
        color: $title-color;
        font-size: 0.7rem;
      }
    }

    .rdrEndEdge {
      &::after {
        content: "Out";
        position: absolute;
        right: 0.3em !important;
        top: -1px;
        color: $title-color;
        font-size: 0.7rem;
      }
    }

    .rdrStartEdge.rdrEndEdge,
    .rdrDayStartPreview,
    .rdrDayEndPreview {
      right: 0px !important;
      left: 0px !important;
      height: 1rem !important;
      border-top-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;

      &::before {
        content: "In";
        position: absolute;
        left: 0.6em !important;
        top: -1px;
        color: $title-color;
        font-size: 0.7rem;
      }

      &::after {
        content: "Out";
        position: absolute;
        left: 3em !important;
        top: -1px;
        color: $title-color;
        font-size: 0.7rem;
      }
    }
  }
}

@media (min-width: 1150px) {
  .calendar-wrap {
    .rdrDayStartPreview,
    .rdrStartEdge {
      left: 4.6em !important;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 0px;
    }

    .rdrDayEndPreview,
    .rdrEndEdge {
      right: 4.6em !important;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 8px;
    }

    .rdrStartEdge {
      &::before {
        content: "In";
        position: absolute;
        left: 0.6em !important;
        top: -1px;
        color: $title-color;
        font-size: 0.7rem;
      }
    }

    .rdrEndEdge {
      &::after {
        content: "Out";
        position: absolute;
        right: 0.4em !important;
        top: -1px;
        color: $title-color;
        font-size: 0.7rem;
      }
    }

    .rdrStartEdge.rdrEndEdge,
    .rdrDayStartPreview,
    .rdrDayEndPreview {
      right: 0px !important;
      left: 0px !important;
      height: 1rem !important;
      border-top-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;

      &::before {
        content: "In";
        position: absolute;
        left: 0.6em !important;
        top: -1px;
        color: $title-color;
        font-size: 0.7rem;
      }

      &::after {
        content: "Out";
        position: absolute;
        left: 3em !important;
        top: -1px;
        color: $title-color;
        font-size: 0.7rem;
      }
    }
  }
}

@media (min-width: 1448px) {
  .calendar-wrap {
    .rdrDayStartPreview,
    .rdrStartEdge {
      left: 6em !important;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 0px;
    }

    .rdrDayEndPreview,
    .rdrEndEdge {
      right: 6em !important;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 8px;
    }

    .rdrStartEdge.rdrEndEdge,
    .rdrDayStartPreview,
    .rdrDayEndPreview {
      right: 0px !important;
      left: 0px !important;
      height: 1rem !important;
      border-top-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;

      &::before {
        content: "In";
        position: absolute;
        left: 1em !important;
        top: -1px;
        color: $title-color;
        font-size: 0.7rem;
      }

      &::after {
        content: "Out";
        position: absolute;
        left: 7em !important;
        top: -1px;
        color: $title-color;
        font-size: 0.7rem;
      }
    }
  }
}

@media (min-width: 1482px) {
  .calendar-wrap {
    // .rdrStartEdge.rdrEndEdge.predefinedRanges {
    //     & {
    //         right: 5px !important;
    //     }

    //     &::before {
    //         content: 'In';
    //         position: absolute;
    //         left: 3em !important;
    //         top: -1px;
    //         color: $title-color;
    //         font-size: .7rem;
    //     }

    //     &::after {
    //         content: 'Out';
    //         position: absolute;
    //         left: 5em !important;
    //         top: -1px;
    //         color: $title-color;
    //         font-size: .7rem;
    //     }
    // }

    .rdrDayStartPreview,
    .rdrStartEdge {
      left: 6em !important;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 0px;
    }

    .rdrDayEndPreview,
    .rdrEndEdge {
      right: 6em !important;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 8px;
    }

    .rdrStartEdge.rdrEndEdge,
    .rdrDayStartPreview,
    .rdrDayEndPreview {
      right: 0px !important;
      left: 0px !important;
      height: 1rem !important;
      border-top-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;

      &::before {
        content: "In";
        position: absolute;
        left: 1em !important;
        top: -1px;
        color: $title-color;
        font-size: 0.7rem;
      }

      &::after {
        content: "Out";
        position: absolute;
        left: 5em !important;
        top: -1px;
        color: $title-color;
        font-size: 0.7rem;
      }
    }
  }
}

@media (min-width: 1792px) {
  .calendar-wrap {
    .rdrDayStartPreview,
    .rdrStartEdge {
      left: 8em !important;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 0px;
    }

    .rdrDayEndPreview,
    .rdrEndEdge {
      right: 8em !important;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 8px;
    }

    .rdrStartEdge.rdrEndEdge,
    .rdrDayStartPreview,
    .rdrDayEndPreview {
      right: 0px !important;
      left: 0px !important;
      height: 1rem !important;
      border-top-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;

      &::before {
        content: "In";
        position: absolute;
        left: 1em !important;
        top: -1px;
        color: $title-color;
        font-size: 13px;
      }

      &::after {
        content: "Out";
        position: absolute;
        left: 7em !important;
        top: -1px;
        color: $title-color;
        font-size: 12px;
      }
    }
  }
}

@media (min-width: 1792px) {
  .calendar-wrap {
    .rdrDayStartPreview,
    .rdrStartEdge {
      left: 8em !important;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 0px;
    }

    .rdrDayEndPreview,
    .rdrEndEdge {
      right: 8em !important;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 8px;
    }

    .rdrStartEdge.rdrEndEdge,
    .rdrDayStartPreview,
    .rdrDayEndPreview {
      right: 0px !important;
      left: 0px !important;
      height: 1rem !important;
      border-top-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;

      &::before {
        content: "In";
        position: absolute;
        left: 1em !important;
        top: -1px;
        color: $title-color;
        font-size: 13px;
      }

      &::after {
        content: "Out";
        position: absolute;
        left: 7em !important;
        top: -1px;
        color: $title-color;
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .calendar-wrap {
    .rdrDayStartPreview,
    .rdrStartEdge {
      left: 3.4em;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 0px;
    }

    .rdrDayEndPreview,
    .rdrEndEdge {
      right: 3.4em;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 8px;
    }

    .rdrStartEdge {
      &::before {
        content: "In";
        position: absolute;
        left: 0.6em !important;
        top: -1px;
        color: $title-color;
        font-size: 0.7rem;
      }
    }

    .rdrEndEdge {
      &::after {
        content: "Out";
        position: absolute;
        right: 0.5em !important;
        top: -1px;
        color: $title-color;
        font-size: 0.7rem;
      }
    }

    .rdrStartEdge.rdrEndEdge,
    .rdrDayStartPreview,
    .rdrDayEndPreview {
      right: 0px !important;
      left: 0px !important;
      height: 1rem !important;
      border-top-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;

      &::before {
        content: "In";
        position: absolute;
        left: 0.6em !important;
        top: -1px;
        color: $title-color;
        font-size: 0.7rem;
      }

      &::after {
        content: "Out";
        position: absolute;
        left: 3em !important;
        top: -1px;
        color: $title-color;
        font-size: 0.7rem;
      }
    }
  }
}

@media (max-width: 981px) {
  .calendar-wrap {
    .rdrDayStartPreview,
    .rdrStartEdge {
      left: 3.4em;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 0px;
    }

    .rdrDayEndPreview,
    .rdrEndEdge {
      right: 3.4em;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 8px;
    }

    .rdrStartEdge {
      &::before {
        content: "";
        position: absolute;
        left: 1em !important;
        top: -1px;
        color: $title-color;
        font-size: 13px;
      }
    }

    .rdrEndEdge {
      &::after {
        content: "";
        position: absolute;
        left: 4em !important;
        top: -1px;
        color: $title-color;
        font-size: 12px;
      }
    }

    .rdrStartEdge.rdrEndEdge,
    .rdrDayStartPreview,
    .rdrDayEndPreview {
      right: 0px !important;
      left: 0px !important;
      height: 1rem !important;
      border-top-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;

      &::before {
        content: "";
        position: absolute;
        left: 1em !important;
        top: -1px;
        color: $title-color;
        font-size: 13px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 7em !important;
        top: -1px;
        color: $title-color;
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 780px) {
  .calendar-wrap {
    .rdrDayStartPreview,
    .rdrStartEdge {
      left: 3.4em;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 0px;
    }

    .rdrDayEndPreview,
    .rdrEndEdge {
      right: 3.4em;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 8px;
    }

    .rdrStartEdge {
      &::before {
        content: "";
        position: absolute;
        left: 1em !important;
        top: -1px;
        color: $title-color;
        font-size: 13px;
      }
    }

    .rdrEndEdge {
      &::after {
        content: "";
        position: absolute;
        left: 4em !important;
        top: -1px;
        color: $title-color;
        font-size: 12px;
      }
    }

    .rdrStartEdge.rdrEndEdge,
    .rdrDayStartPreview,
    .rdrDayEndPreview {
      right: 0px !important;
      left: 0px !important;
      height: 1rem !important;
      border-top-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;

      &::before {
        content: "";
        position: absolute;
        left: 1em !important;
        top: -1px;
        color: $title-color;
        font-size: 13px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 7em !important;
        top: -1px;
        color: $title-color;
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 695px) {
  .calendar-wrap {
    .rdrDayStartPreview,
    .rdrStartEdge {
      left: 3.4em;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 0px;
    }

    .rdrDayEndPreview,
    .rdrEndEdge {
      right: 3.4em;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 8px;
    }

    .rdrStartEdge {
      &::before {
        content: "";
        position: absolute;
        left: 1em !important;
        top: -1px;
        color: $title-color;
        font-size: 13px;
      }
    }

    .rdrEndEdge {
      &::after {
        content: "";
        position: absolute;
        left: 4em !important;
        top: -1px;
        color: $title-color;
        font-size: 12px;
      }
    }

    .rdrStartEdge.rdrEndEdge,
    .rdrDayStartPreview,
    .rdrDayEndPreview {
      right: 0px !important;
      left: 0px !important;
      height: 1rem !important;
      border-top-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;

      &::before {
        content: "";
        position: absolute;
        left: 1em !important;
        top: -1px;
        color: $title-color;
        font-size: 13px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 7em !important;
        top: -1px;
        color: $title-color;
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 555px) {
  .calendar-wrap {
    .rdrDayStartPreview,
    .rdrStartEdge {
      left: 2.8em;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 0px;
    }

    .rdrDayEndPreview,
    .rdrEndEdge {
      right: 2.8em;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 8px;
    }

    .rdrStartEdge {
      &::before {
        content: "";
        position: absolute;
        left: 1em !important;
        top: -1px;
        color: $title-color;
        font-size: 13px;
      }
    }

    .rdrEndEdge {
      &::after {
        content: "";
        position: absolute;
        left: 4em !important;
        top: -1px;
        color: $title-color;
        font-size: 12px;
      }
    }

    .rdrStartEdge.rdrEndEdge,
    .rdrDayStartPreview,
    .rdrDayEndPreview {
      right: 0px !important;
      left: 0px !important;
      height: 1rem !important;
      border-top-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;

      &::before {
        content: "";
        position: absolute;
        left: 1em !important;
        top: -1px;
        color: $title-color;
        font-size: 13px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 7em !important;
        top: -1px;
        color: $title-color;
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 415px) {
  .calendar-wrap {
    .rdrDayStartPreview,
    .rdrStartEdge {
      left: 2.3em;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 0px;
    }

    .rdrDayEndPreview,
    .rdrEndEdge {
      right: 2.3em;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 8px;
    }

    .rdrStartEdge {
      &::before {
        content: "";
        position: absolute;
        left: 1em !important;
        top: -1px;
        color: $title-color;
        font-size: 13px;
      }
    }

    .rdrEndEdge {
      &::after {
        content: "";
        position: absolute;
        left: 4em !important;
        top: -1px;
        color: $title-color;
        font-size: 12px;
      }
    }

    .rdrStartEdge.rdrEndEdge,
    .rdrDayStartPreview,
    .rdrDayEndPreview {
      right: 0px !important;
      left: 0px !important;
      height: 1rem !important;
      border-top-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;

      &::before {
        content: "";
        position: absolute;
        left: 1em !important;
        top: -1px;
        color: $title-color;
        font-size: 13px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 7em !important;
        top: -1px;
        color: $title-color;
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 387px) {
  .calendar-wrap {
    .rdrDayStartPreview,
    .rdrStartEdge {
      left: 2em;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 0px;
    }

    .rdrDayEndPreview,
    .rdrEndEdge {
      right: 2em;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 8px;
    }

    .rdrStartEdge {
      &::before {
        content: "";
        position: absolute;
        left: 1em !important;
        top: -1px;
        color: $title-color;
        font-size: 13px;
      }
    }

    .rdrEndEdge {
      &::after {
        content: "";
        position: absolute;
        left: 4em !important;
        top: -1px;
        color: $title-color;
        font-size: 12px;
      }
    }

    .rdrStartEdge.rdrEndEdge,
    .rdrDayStartPreview,
    .rdrDayEndPreview {
      right: 0px !important;
      left: 0px !important;
      height: 1rem !important;
      border-top-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;

      &::before {
        content: "";
        position: absolute;
        left: 1em !important;
        top: -1px;
        color: $title-color;
        font-size: 13px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 7em !important;
        top: -1px;
        color: $title-color;
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 328px) {
  .calendar-wrap {
    .rdrDayStartPreview,
    .rdrStartEdge {
      left: 1.8em;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 0px;
    }

    .rdrDayEndPreview,
    .rdrEndEdge {
      right: 1.8em;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 8px;
    }

    .rdrStartEdge {
      &::before {
        content: "";
        position: absolute;
        left: 1em !important;
        top: -1px;
        color: $title-color;
        font-size: 13px;
      }
    }

    .rdrEndEdge {
      &::after {
        content: "";
        position: absolute;
        left: 4em !important;
        top: -1px;
        color: $title-color;
        font-size: 12px;
      }
    }

    .rdrStartEdge.rdrEndEdge,
    .rdrDayStartPreview,
    .rdrDayEndPreview {
      right: 0px !important;
      left: 0px !important;
      height: 1rem !important;
      border-top-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;

      &::before {
        content: "";
        position: absolute;
        left: 1em !important;
        top: -1px;
        color: $title-color;
        font-size: 13px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 7em !important;
        top: -1px;
        color: $title-color;
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 670px) {
  .property-details-form {
    .primary-btn {
      width: 80px !important;
      height: 23px;
      text-align: center;
      margin: 0 auto;
      padding: 0;
      display: table-cell;
      vertical-align: middle;
      border-radius: 5px;
    }
  }

  .property-document-form {
    .secondary-btn {
      width: 80px !important;
      height: 23px;
      text-align: center;
      margin: 0 auto;
      padding: 0;
      display: table-cell;
      vertical-align: middle;
    }
  }
}
