.card-profile {
    h5 {
        font-size: 1rem;
    }

    p {
        font-size: 0.9rem;
        margin-bottom: 0;

        span {
            font-size: 0.8rem;
        }
    }

    .card-profile-image img {
        transform: translate(-50%, -30%) scale(1);
    }
}

.card-container {

    height: 100%;

    @media (min-width: 1281px) {
        max-height: 125px;
        margin-bottom: 10px;
    }

    @media (max-width: 1280px) {
        max-height: 130px;
    }

    @media (max-width: 1200px) {
        max-height: 140px;
    }

    @media (max-width: 917px) {
        max-height: 160px;
    }

    @media (max-width: 963px) {
        max-height: 150px;
    }

    @media (max-width: 768px) {
        max-height: 150px;
    }

    @media (max-width: 593px) {
        max-height: 130px;
        height: auto;
    }

    @media (max-width: 435px) {
        max-height: 150px;
    }
}

// @media (max-width: 628px) {
//     .owner-header {
//         .card-body {
//             padding: 0;
//             margin: 0;
//         }

//         h5 {
//             text-align: center;
//             margin-top: 0 !important;
//             font-size: 16px;
//         }

//     }
// }

// @media (min-width: 464px) {
//     .owner-header {
//         .col {
//             width: 50%;
//         }

//         .col:first-child {
//             width: 100%;
//         }
//     }
// }

// @media (min-width: 540px) {
//     .owner-header {
//         .col {
//             width: 50%;
//         }

//         .col:nth-child(2) {
//             width: 100%;
//         }
//     }
// }

// @media (min-width: 540px) {
//     .owner-header {
//         .col {
//             width: 50%;
//         }

//         .col:nth-child(2) {
//             width: 100%;
//         }

//         h5 {
//             text-align: center;
//             margin-top: 0 !important;
//             font-size: 16px;
//         }
//     }
// }

// @media (min-width: 672px) {
//     .owner-header {
//         .row {
//             justify-content: space-between;
//         }

//         .col {
//             width: 100%;
//         }

//         .col:nth-child(2) {
//             width: 100%;
//         }

//         h5 {
//             text-align: center;
//             margin-top: 0 !important;
//             font-size: 16px;
//         }
//     }
// }

// @media (min-width: 1000px) {
//     .owner-header {
//         // .col:first-child {
//         //     width: 5% !important;
//         // }

//         h5 {
//             padding-top: 35px !important;
//             margin: 0;
//         }

//     }
// }