// Reservation styles

.reservation-card-body {
    label {
        color: $label-color;
        font-size: 0.875rem;
        font-weight: 600;
    }
}

.opportunity-cost {
    display: block;
    text-align: right;
    width: 100%;
}

.badge-container {
    gap: 2rem;
    font-size: 1rem;
}

.property-insights {
    display: flex;
    justify-content: end;
    padding-right: 1.2rem;
    text-transform: uppercase;
    gap: 2.3rem;
    font-size: .8rem;
    font-family: inherit;

    p {
        text-align: center;
        margin: 0;
    }
}

.custom-conrtol-title {
    width: 100%;
}


@media (max-width: 513px) {
    .badge-container {
        gap: 1rem;
        font-size: 0.5rem !important;
        margin: 0;
    }

    .property-insights {
        display: flex;
        justify-content: center;
        text-transform: uppercase;
        gap: 1rem;
        font-size: .5rem;
        font-family: inherit;
    
        p {
            text-align: center;
            margin: 0;
            font-size: .8rem;
        }
    }
}

@media (max-width: 870px) {
    .form-title {
        font-size: .9rem !important;
    }

    .total-label {
        font-size: .7rem !important;
    }
}

@media (max-width: 795px) {
    .form-title {
        font-size: .8rem !important;
    }

    .total-label {
        font-size: .6rem !important;
    }

    .custom-control-label {
        font-size: .8rem !important;
    }

    .custom-conrtol-title {
        font-size: .9rem !important;
    }
}

@media (max-width: 795px) {
    .form-title {
        font-size: .9rem !important;
    }

    .total-label {
        font-size: .8rem !important;
    }


    .custom-control-label {
        font-size: .8rem !important;
    }

    .custom-conrtol-title {
        font-size: .9rem !important;
        width: 100%;
    }
}

@media (max-width: 800px) {
    .form-title {
        font-size: .8rem !important;
    }

    .total-label {
        font-size: .7rem !important;
    }
}

.time-picker {
    background-color: white;

    &:hover {
        border-color: #f7fafc;
        border-radius: 0.4rem;
        background-color: white;
    }

    &:active {
        border-color: #f7fafc;
        border-radius: 0.4rem;
        background-color: white;
    }

    input:hover {
        border-color: #f7fafc;
        background-color: white;
    }

    input {
        color: #8898aa;
        height: 3rem;
        font-weight: 400;
        height: .8rem;
        font-family: Open Sans, sans-serif;
        font-size: 0.875rem;
        border-radius: 0.4rem;
        background-color: white;
    }

    svg {
        height: 1.1rem;
        color: black;
    }
}

.no-wrap {
    flex-wrap: nowrap !important;
    display: flex;
    align-items: center;
}


.date-range-calendar {
    height: 2.563rem;
}



