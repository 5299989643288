// Variables
$main-btn-color: #4A90E2;
$sub-btn-color: #4A4A4A;
$white-color: #FFFFFF;
$label-color: #525F7F;
$title-color: #32325D;
$input-color: #8898AA;
$link-color: #4D7BCA;
$step-bgcolor: #00A15C;
$menu-border-color: #4A4A4A;
$menu-bg-color: #ADB5BD;
$cal-border-color: #f4f5f7;