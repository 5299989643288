@media (max-width: 628px) {
}

th {
  background-color: #ffffff;
  color: $table-head-color;

  a {
    color: $table-head-color;
  }
}

.property-access-table {
  overflow-x: hidden;
  overflow-y: hidden;
}

a.property-link {
  color: #0350d2;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
}

a.property-link:hover {
  text-decoration: underline;
}

span.property-address-label {
  font-size: 16px;
}