
.block90 {
  display: inline-block;
    vertical-align: top;
    padding-right: 2%;
  }
  .boldStyle {
    font-weight: bold;
  }
  .halfBlock {   
    padding-right: 2%;
  }
  .block5 {
    display: inline-block;
    padding-right: 2%;
    padding-left: 5%;
  }

  .block10 {
    
    padding-right: 2%;
  }

  .spanUnderline {
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: black;
  }

@media only screen and (min-width: 992px) {
  .block90 {
    max-width: 90%;
    display: inline-block;
    vertical-align: top;
    padding-right: 2%;
  }
  .boldStyle {
    font-weight: bold;
  }
  .halfBlock {
    max-width: 45%;
    display: inline-block;
    vertical-align: top;
    padding-right: 2%;
  }
  .block5 {
    max-width: 10%;
    display: inline-block;
    vertical-align: top;
    padding-right: 2%;
    padding-left: 5%;
  }

  .block10 {
    width: 10%;
    display: inline-block;
    vertical-align: top;
    padding-right: 2%;
  }

  .spanUnderline {
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: black;
  }
}
