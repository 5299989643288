// Custom Navbar style
.navbar-vertical {

  &.navbar-expand-xs {
    .navbar-nav {
      .nav-link {
        padding: 0.45rem 1.5rem;
      }

      li > .nav-link {
        border-left: 4px solid white;
      }

      li:hover > .nav-link,
      li.active > .nav-link {
        color: $label-color;
        border-left: 4px solid #eb361d;
        background-color: #f1f4f9;
        font-weight: bold;
      }
    }
  }

  .request-btn {
    width: 90%;
    margin: 0 auto;
  }
}

.navbar-top {
  .request-btn {
    padding: 5px 30px;
  }
}

.financial-navbar,
.owner-navbar {
  overflow-x: auto;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.owner-subnav,
.financial-subnav,
.onboarding-subnav {
  height: 5rem;

  .nav-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-right: 2rem;
    font-size: 1.125rem;

    &:hover,
    &.active {
      color: $label-color;
      border-bottom: 4px solid $menu-border-color;
    }
  }
}

.onboarding-subnav {
  .nav-link {
    position: relative;
    padding-left: 52px !important;
    padding-right: 10px !important;
    padding-bottom: 10px;

    &::before {
      content: '';
      width: 32px;
      height: 32px;
      position: absolute;
      left: 5px;
      top: 0;
      background: $menu-bg-color;
      border-radius: 50%;
    }

    &::after {
      content: '';
      position: absolute;
      width: 11px;
      height: 19px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      left: 15px;
      top: 3px;
    }

    &.verified {
      &::before {
        background: $step-bgcolor;
      }
    }
  }
}

.g-sidenav-hidden {
  .sidenav {
    .request-btn {
      width: 33px;
      margin-left: -0.5rem;

      span {
        display: none;
      }

      i {
        margin-right: 0;
      }
    }

    .fa-angle-left {
      &::before {
        content: "\f105";
        margin: 3px;
      }
    }
  }
}

.g-sidenav-show {
  .sidenav {
    .request-btn {
      width: auto;
      margin-left: 0;

      span {
        display: inline;
      }

      i {
        margin-right: 0.5rem;
      }
    }

    .fa-angle-left {
      &::before {
        content: "\f104";
        margin: 3px;
      }
    }
  }
}

@media (max-width: 625px) {
  .nav-link {
    font-size: .90rem !important;
  }
}

#close-icon {
  width: 30px;
  height: 35px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .1s ease-in-out;
  -moz-transition: .1s ease-in-out;
  -o-transition: .1s ease-in-out;
  transition: .1s ease-in-out;
  cursor: pointer;
}

#close-icon span {
  display: block;
  height: 2px;
  width: 100%;
  background: #f4f4f4;
  border-radius: 2px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .1s ease-in-out;
  -moz-transition: .1s ease-in-out;
  -o-transition: .1s ease-in-out;
  transition: .1s ease-in-out;
  margin-top: 5px;
}


/* Close Icon */

#close-icon {
  padding: 2px;
}

#close-icon span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#close-icon span:nth-child(2) {
  top: 18px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#close-icon span:nth-child(3) {
  top: 36px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#close-icon.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 1px;
  left: 1px;
  position: absolute;
}

#close-icon.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#close-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 22px;
  left: 1px;
  position: absolute;
}

button:focus {
  outline: none !important;

}

.navbar-light .navbar-toggler {
  border-color: none;
  border: 0px;

}

/* Navbar Hamburger animation */

.navbar-brand-img {
  width: 8rem !important;
}
