.p-multiselect {
    width: 12rem;
}

.multiSelectContainer {
    li {
        padding: .4rem .6rem !important;
    }

    li:hover {
        padding: .4rem .6rem;
        background-color: #5e72e4 !important;
    }

    .searchWrapper {
        width: auto;
        background-color: white !important;
        min-width: 100%;

        .option {
            white-space: nowrap;
        }
    }
}