// Custom button style
.primary-btn,
.secondary-btn {
    background: $main-btn-color;
    // border-radius: 8px;
    border: none;
    font-weight: normal;
    color: white;

    &:hover {
        background: $main-btn-color;
        opacity: 0.8;
        transform: unset;
    }

    &:active,
    &:focus {
        outline: unset;
    }

    &.sm-size {
        padding-top: 7px;
        padding-bottom: 7px;
    }
}

.secondary-btn {
    background: $sub-btn-color;

    &:hover {
        background: $sub-btn-color;
        color: $white-color;
    }

    &.disabled {
        background: $sub-btn-color;
        color: $white-color;
    }
}

.request-btn {
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
        font-size: 17px;
        margin-right: 6px
    }

    i.fa-plus {
        font-size: 14px;
    }
}

.profile-photo-wrap {
    .secondary-btn {
        width: 100%;
        padding: 0.5rem 0;
        font-size: 12px;
    }
}

.order-grid-btn,
.order-list-btn {
    background: $white-color;
    width: 35px;
    height: 35px;
    margin: 0 !important;
    padding: 0;

    i {
        color: #172b4d;
    }

    &:hover {
        transform: unset;
    }

    &.active {
        background: $input-color;

        i {
            color: $white-color;
        }
    }
}

.order-list-btn {
    border-radius: 5px 0 0 5px;
    margin-right: 1px !important;
}

.order-grid-btn {
    border-radius: 0 5px 5px 0;
}

.bg-reserve {
    background-color: #4a90e2 !important;
}

.bg-block {
    background-color: #9b9b9b !important;
}

.edit-button {
    font-weight: "600";
    color: '#4D7BCA';
    cursor: 'pointer';
    font-size: '.9rem';
    width: 80px;
    height: 30px;
    margin-top: -18px;
    border: none;

    &:hover {
        color: '#2f60b5';
        border: inherit;
        box-shadow: none;
    }
}

.save-button {
    height: 2.563rem;
    vertical-align: baseline !important;
    padding: .6rem !important;
    font-family: Open Sans, sans-serif !important;
    font-size: 1rem !important;

    .p-button-icon {
        font-size: .8rem;
    }

    .p-button-label {
        font-weight: 400;
        font-size: 16px;
    }
}

.button-financial-container {
    margin: 0.75rem auto 0.75rem 1rem;
}