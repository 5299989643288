// Onboarding styles

.max-width-425 {
    max-width: 425px;
}

img.global-bg {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;
    z-index: 1;
}

.full-height {
    height: 100vh !important;
}

.full-width {
    width: 100%;
}

.flex-center {
    display: flex;
    align-items: center;
}

// ----- Login ----- //
.main-bg {
    width: 100%;
    height: 100vh;
    font-family: Arial, Helvetica, sans-serif;

    .main-content {
        display: flex;
        align-items: center;
        min-height: 100vh;
    }

    .login-wrap,
    .reset-pass-wrap {
        position: relative;
        z-index: 2;

        p {
            font-size: 18px;
            color: $label-color;
        }

        .primary-btn,
        .secondary-btn {
            width: 100%;
        }

        .card-header {
            border-bottom: 1px solid #E5E5E5;
            background: unset;
        }
    }
}

.logo {
    img {
        width: 200px;
        height: auto;
    }
}

// ----- Onboarding ----- //
.bg-info {
    background: rgb(191, 72, 72);
    background: linear-gradient(90deg, rgba(191, 72, 72, 1) 0%, rgba(144, 43, 72, 1) 35%, rgba(76, 2, 72, 1) 100%);
}

.sidenav-header {
    height: auto;
}

.navbar-vertical {
    .navbar-brand {
        padding: 1rem;

        img {
            width: 110px;
            height: auto;
            max-height: unset;
        }
    }
}

.onboarding-wrap,
.dashboard-header {
    position: relative;
    overflow: hidden;

    // z-index: 11;
    .navbar-top {
        position: relative;
        z-index: 9999 !important;
    }

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        background: black;
        // background: rgb(191,72,72);
        // background: linear-gradient(90deg, rgba(191,72,72,1) 0%, rgba(144,43,72,1) 35%, rgba(76,2,72,1) 100%);
        opacity: 0.75;
    }
}

.main-content {
    .navbar-top {
        position: relative;
        z-index: 12;
    }
}

.onboarding-header {
    padding-top: 1.8rem;
    z-index: 3;

    &.full {
        height: calc(100vh - 100px);
    }

    h2 {
        font-size: 2.75rem;
    }

    p {
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.7;
    }

    .primary-btn {
        width: 250px;
    }

    .onboarding-steps {
        background: $white-color;
        border-radius: 8px;
        padding: 25px 2rem 20px 2rem;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            width: 70%;
            left: 15%;
            height: 1px;
            background: $input-color;
            top: 56px;
        }

        p {
            color: $label-color;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.5;
            margin-bottom: 0.5rem;
        }

        span {
            color: #555;
            font-size: 0.9rem;
        }

        .step-item {
            width: 84px;
            margin: 10px auto;
            height: 44px;
            background: $white-color;

            span {
                display: flex;
                width: 44px;
                margin: 0 auto;
                justify-content: center;
                align-items: center;
                height: 44px;
                border: 1px solid $input-color;
                border-radius: 50%;
                background: $white-color;
                font-size: 1.5rem;
                cursor: pointer;
            }

            &.selected {
                span {
                    background: $main-btn-color;
                    border: 1px solid $main-btn-color;
                    color: $white-color;
                }
            }

            &.checked {
                span {
                    background: $step-bgcolor;
                    text-indent: -9999px;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        width: 17px;
                        height: 26px;
                        border: solid white;
                        border-width: 0 4px 4px 0;
                        transform: rotate(45deg);
                        left: 0.75rem;
                        top: 0.2rem;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        width: 7px;
                        height: 13px;
                        border: solid white;
                        border-width: 0 3px 3px 0;
                        transform: rotate(45deg);
                        left: -1.4rem;
                        top: 0.2rem;
                    }
                }
            }
        }
    }
}

.property-details-form .section-body {
    padding-left: 1%;
}

.property-details-form .file {
    margin-left: 23%;
}

.owner-profile-form {
    z-index: 50 !important;
}

.onboarding-form,
.owner-profile-form,
.property-details-form,
.property-form,
.reservation-form {
    position: relative;
    z-index: 5;

    h3 {
        font-size: 1rem;
    }

    h4 {
        color: $input-color;
        font-size: 0.75rem;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    p {
        font-size: 0.875rem;
        color: $label-color;
        margin: 0;
    }

    label {
        color: $label-color;
        font-size: 0.875rem;
        font-weight: 600;
    }

    .custom-control-label {
        color: #888;
    }

    table {
        td {
            padding: 0.3rem 0.2rem;
            border: none;
        }

        h4,
        p {
            font-size: 0.8rem;
            color: $title-color;
            margin: 0;
            margin-top: 0.6rem;
            font-weight: 600;
            // font-family: Open Sans, sans-serif;
        }
    }
}

// .reservation-form {
//     background-color: #f8f9fe;
//     padding: 2px 2px 0 4px;
// }

.container-fluid.onboarding-form,
.container-fluid.property-form {
    z-index: 13;
}

.card-header {
    border-bottom: none;
}

.card-body.bg-custom {
    background: #f7fafc;
}

.bg-default {
    background-color: $white-color !important;
}

.profile-photo-wrap {
    p {
        color: $label-color;
        font-size: 1rem;
        font-weight: 500;

        small {
            color: #888;
            font-size: 0.8rem;
        }
    }

    #profile-photo {
        object-fit: cover;
        border-radius: 50%;
    }

    .dz-preview-single {
        border-radius: 50%;
    }
}