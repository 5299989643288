@media (max-width: 500px) {
    .main-content {
        width: 100%;
    }
}

// footer 
body {
    position: relative;
    min-height: 100vh;

    .main-content-wrapper {
        padding-bottom: 50px;
        /* Height of the footer */
    }

    footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 65px;
        font-size: .9rem;
        margin-top: 30px;
        /* Height of the footer */
    }
}

@media only screen and (min-width: 1200px) {
    body {
        position: relative;
        min-height: 100vh;

        .main-content-wrapper {
            padding-bottom: 50px;
            /* Height of the footer */
        }

        footer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 0;
            left: 270px;
            right: 0;
            height: 65px;
            font-size: .9rem;
            margin-top: 30px;
            /* Height of the footer */
        }
    }
}

// footer


// dashboard page styles

.header-body-row {
    display: 'flex';
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.dashboard-tables-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    box-sizing: border-box;
    margin: 0 1.8rem 2rem;
    max-height: 350px;
    gap: 2rem;

    .dashboard-tasks-table {
        flex: 1;
        max-height: 350px;
        padding: 0 1rem;
        background-color: #fff;
        box-shadow: 0 2px 10px rgba(134, 128, 128, 0.2);
        border-radius: 1.2%;

        .property-header {
            padding: .8rem 0 0 .8rem;
            font-size: 1.0625rem;
        }

        .list {
            list-style-type: none;
            margin: 0;
            padding-left: 1rem;

            li {
                padding: .5rem 0;
                border: 1px solid #e9ecef;
                border-width: 0 0 1px;
            }

            li:last-child {
                border-bottom: 0;
            }
        }
    }

    .dashboard-property-table {
        flex: 1;
        // max-height: 1000px;
        // overflow-y: scroll;
        padding: 0 1rem;
        background-color: #fff;
        box-shadow: 0 2px 10px rgba(134, 128, 128, 0.2);
        border-radius: 1.2%;

        .property-header {
            padding: .8rem 0 0 .8rem;
            font-size: 1.0625rem;
        }

        .list {
            list-style-type: none;
            margin: 0;
            padding-left: 1rem;

            li {
                padding: .5rem 0;
                border: 1px solid #e9ecef;
                border-width: 0 0 1px;
            }

            li:last-child {
                border-bottom: 0;
            }
        }
    }
}


.header-body-row {
    flex-wrap: wrap;

    #dashboard-icon {
        font-size: 1.23rem;
        background-color: #4a4a4a !important;
    }

    // .col {
    //     flex-basis: calc(25% - 20px)
    // }

    .dashboard-header-card-body {
        margin-top: -10px;
        max-height: 130px;
    }
}

@media only screen and (max-width: 767px) {
    .dashboard-header {
        position: relative;
        overflow: hidden;

        img {
            width: 100%;
            height: 100% !important;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            object-fit: cover;
        }
    }

    .dashboard-tables-container {
        flex-direction: column;
        max-height: fit-content;

        .dashboard-property-table,
        .dashboard-tasks-table {
            max-height: fit-content;
        }
    }

    .header-body-row {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 1.7rem;

        .col {
            padding: 0 1rem;
        }

        #dashboard-icon {
            font-size: .2rem;
            background-color: #4a4a4a !important;
        }

        .dashboard-header-card-body {
            margin-top: -10px;
            max-height: 100px;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    // .dashboard-tables-container {
    //     flex-direction: row;
    //     align-items: flex-start;
    //     flex-wrap: wrap;

    //     .dashboard-property-table {
    //         width: 100%;
    //         margin-bottom: 10px;
    //         max-height: 300px;
    //         overflow-y: auto;
    //     }

    //     .dashboard-tasks-table {
    //         width: calc(50% - 5px);
    //         margin-left: 10px;
    //         max-height: 300px;
    //         overflow-y: auto;
    //     }
    // }
    .dashboard-tables-container {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;
        box-sizing: border-box;
        margin: 0 1.8rem 2rem;
        max-height: 350px;
        gap: 2rem;

        .dashboard-property-table,
        .dashboard-tasks-table {
            flex: 1;
            max-height: 350px;
            padding: 0 1rem;
            background-color: #fff;
            overflow-y: auto;
            box-shadow: 0 2px 10px rgba(134, 128, 128, 0.2);
            border-radius: 1.2%;
        }
    }

    .header-body-row {
        max-height: 80px;
        gap: 0;

        .dashboard-header-card-body {
            max-height: 80px;

            .col {
                padding-right: 0;
                padding-left: 10px;
            }

            h5 {
                font-size: 0.5rem;
            }

            span {
                font-size: .8rem;
            }

            #dashboard-icon {
                width: .8rem;
                height: .8rem;
                border-radius: 50%;

                i {
                    font-size: .6rem;
                    background-color: #4a4a4a !important;
                }
            }
        }
    }
}

@media only screen and (min-width: 991px) and (max-width: 1249px) {
    .header-body-row {
        max-height: 80px;

        .dashboard-header-card-body {
            max-height: 90px;

            h5 {
                font-size: 0.6rem;
            }

            span {
                font-size: .8rem;
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    .header-body-row {
        display: 'flex';
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    .dashboard-header-card-body {
        #dashboard-icon i {
            font-size: 1rem;
            background-color: #4a4a4a !important;
        }
    }

    .dashboard-tables-container {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;
        box-sizing: border-box;
        margin: 0 1.8rem 2rem;
        max-height: 350px;
        gap: 2rem;

        .dashboard-property-table,
        .dashboard-tasks-table {
            flex: 1;
            max-height: 350px;
            padding: 0 1rem;
            background-color: #fff;
            overflow-y: auto;
            box-shadow: 0 2px 10px rgba(134, 128, 128, 0.2);
            border-radius: 1.2%;

            .property-header {
                padding: .8rem 0 0 .8rem;
                font-size: 1.0625rem;
            }

            .list {
                list-style-type: none;
                margin: 0;
                padding-left: 1rem;

                li {
                    padding: .5rem 0;
                    border: 1px solid #e9ecef;
                    border-width: 0 0 1px;
                }

                li:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
}

// dashboard page styles