// Form custom style
.form-control,
.input-group-text {
    border-radius: 0.4rem;
}

.focused .input-group-alternative {
    box-shadow: 0 2px 4px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

a {
    color: $link-color;
}

.or-color {
    color: $input-color;
}

.line-height-12 {
    line-height: 1.2;
}

.inline-block {
    display: inline-block;
}

.custom-radio {
    .custom-control-label::before {
        border: none;
        top: 0.13rem;
    }

    .custom-control-label::after {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
        border-radius: 50%;
        width: 1.1rem;
        height: 1.1rem;
        top: 0.1rem;
    }

    .custom-control-input:checked~.custom-control-label::before {
        border: none;
        background: $main-btn-color;
        width: 0.8rem;
        height: 0.75rem;
        margin-left: 0.16rem;
        margin-top: 0.15rem;
    }

    .custom-control-input:checked~.custom-control-label::after {
        // border: 1px solid #888;
        border-radius: 50%;
        // box-shadow: unset;
    }

    .custom-control-input:not(:disabled):active~.custom-control-label::before {
        border: none;
        background: none;
    }
}

.custom-checkbox {

    .custom-control-label::before,
    .custom-control-label::after {
        width: 1.2rem;
        height: 1.2rem;
        top: 0.1rem;
        border: none;
    }

    .custom-control-label::after {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
    }

    .custom-control-input:checked~.custom-control-label::before {
        background: #4a90e2;
        border: none;
    }

    .custom-control-input:checked~.custom-control-label::after {
        width: 7px;
        height: 13px;
        border: solid $white-color;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        left: -1.4rem;
        top: 0.2rem;
    }

    .badge-dot {
        top: -1px;

        i {
            width: 10px;
            height: 10px;
        }
    }
}

#profile-photo {
    width: 180px;
    height: 180px;
    margin: 0 auto;

    .dz-message {
        background: #f4f5f7;
        padding: 0;
        width: 180px;
        height: 180px;
        border-radius: 50%;

        button {
            display: none;
        }
    }
}

.search-form {
    .input-group {
        width: 100%;
    }
}

.total-label {
    color: $input-color;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 500;

    span {
        color: $title-color;
        font-size: 1rem;
        font-weight: bold;
    }
}

.form-text.helper-message {
    color: red !important;
}

// .without_ampm::-webkit-datetime-edit-ampm-field {
//     display: none;
//   }
//   input[type=time]::-webkit-clear-button {
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     -o-appearance: none;
//     -ms-appearance:none;
//     appearance: none;
//     margin: -10px; 
//   }

.menu {
    height: 15rem;
    overflow-y: scroll;
}

.document-dropdown {
    margin: 0;
    padding-left: 10px !important;
}

.form-group-field{
    margin-bottom: 0 !important;
}

.horizontal-line{
    margin: 1.2rem 0;
}

@media (max-width: 500px) {
    .document-form {
        .dropdown-container{
            flex-direction: column;
            gap: 10px;
        }
    }
}